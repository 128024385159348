.bannerImage {
  width: 100%;
  object-fit: cover;
  position: inherit;
  border-radius: 4px;
}

.talentBanner {
  background-color: #edf5ff;
  padding: 76px 120px;
  display: flex;
  justify-content: space-between;
  background-image: url(../../assets/icons/background-circles-polaroid-talent.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .leftElement {
    display: flex;
    gap: 40px;

    .leftContent {
      width: 446px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3.75rem;

      .descriptionEl {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .firstElement {
          display: flex;
          gap: 16px;
        }
      }
    }
  }

  .rightElement {
    height: 630px;
    width: fit-content;
  }
}

.backgrounBannerDownloadApp {
  background: linear-gradient(to left, #edf5ff 55%, #77b2fe 45%);

  .bannerDownloadApp {
    display: flex;
    justify-content: space-between;
    padding-right: 8%;
    background-image: url(../../assets/icons/background-talent-store.svg);
    background-position-x: left;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 45%;

    .image {
      width: 2400px;
      height: 1200px;
    }

    .informations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4.5%;

      .descriptions {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .area-description {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .sub-title {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            .labelContent {
              color: rgba(0, 0, 0, 0.8);
            }
          }

          .labelContent {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }

    .stores {
      display: flex;
      gap: 16px;

      img {
        cursor: pointer;
      }
    }
  }
}

.BannerModelLoginContent {
  .circles {
    .circle001 {
      z-index: 5;
      position: relative;
      top: -346px;
      left: 130px;
      background-image: linear-gradient(#5C079F, #8399E900);
      opacity: 0.6;
      width: 692px;
      height: 692px;
      border-radius: 692px;
    }

    .circle002 {
      z-index: 1;
      position: relative;
      top: -692px;
      left: 130px;
      background-image: linear-gradient(#5C079F, #8399E900);
      opacity: 0.6;
      width: 692px;
      height: 692px;
      border-radius: 692px;
    }

    .circle003 {
      z-index: 1;
      position: relative;
      top: -1038px;
      left: 130px;
      background-image: linear-gradient(#5C079F, #8399E900);
      opacity: 0.6;
      width: 692px;
      height: 692px;
      border-radius: 692px;
    }
  }

  .imageModel {
    z-index: 10;
    position: relative;
    top: -2050px;
  }
}

@media (max-width: 1171px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to left, #edf5ff 65%, #77b2fe 35%);

    .bannerDownloadApp {
      padding-right: 2%;
      background-size: 35%;

      .image {
        height: 50rem;
      }

      .informations {
        .header {
          .labelTitleContent {
            font-size: 1.5rem;
          }
        }

        .descriptions {
          gap: 2rem;

          .area-description {
            gap: 0.875rem;

            .sub-title {
              .labelContent {
                font-size: 1rem;
              }
            }

            .labelContent {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 53.6%, #77b2fe 46.4%);

    .bannerDownloadApp {
      flex-direction: column;
      align-items: center;
      background-image: url(../../assets/icons/background-talent-store-mobile.svg);
      background-position-x: left;
      background-position-y: top;
      background-repeat: repeat-x;
      background-size: 54.6%;

      .image {
        height: 36rem;
      }

      .informations {
        padding: 7%;

        .header {
          margin-bottom: 2rem;
        }

        .stores {
          margin-top: 2rem;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 53.7%, #77b2fe 46.3%);

    .bannerDownloadApp {
      background-size: 63.6%;
    }
  }
}

@media (max-width: 768px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 54.5%, #77b2fe 45.5%);

    .bannerDownloadApp {
      background-size: 85%;
    }
  }
}

@media (max-width: 640px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 55.6%, #77b2fe 44.4%);

    .bannerDownloadApp {
      background-size: 100%;
    }
  }
}

@media (max-width: 500px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 62.6%, #77b2fe 37.4%);

    .bannerDownloadApp {
      margin-top: 5rem;
      background-size: 100%;

      .image {
        height: 28rem;
      }
    }
  }
}

@media (max-width: 425px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 64.6%, #77b2fe 35.4%);

    .bannerDownloadApp {
      background-size: 115%;
    }
  }
}

@media (max-width: 415px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 62.3%, #77b2fe 37.7%);

    .bannerDownloadApp {
      background-size: 115%;
    }
  }
}

@media (max-width: 400px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 63.4%, #77b2fe 36.6%);

    .bannerDownloadApp {
      background-size: 115%;
    }
  }
}

@media (max-width: 375px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 63.7%, #77b2fe 36.3%);

    .bannerDownloadApp {
      background-size: 125%;
    }
  }
}

@media (max-width: 353px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 64.7%, #77b2fe 35.3%);

    .bannerDownloadApp {
      background-size: 130%;
    }
  }
}

@media (max-width: 357px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 65.1%, #77b2fe 34.9%);

    .bannerDownloadApp {
      background-size: 140%;
    }
  }
}

@media (max-width: 325px) {
  .backgrounBannerDownloadApp {
    background: linear-gradient(to top, #edf5ff 65.8%, #77b2fe 34.2%);

    .bannerDownloadApp {
      background-size: 140%;
    }
  }
}

.talentMovileBanner {
  height: 480px;
  background-color: #090633;
  background-image: url(../../assets/icons/background-talent.svg);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 7%;
  padding-right: 7%;

  .image {
    margin-top: 12rem;

    img {
      height: 500px;
    }
  }

  .description {
    color: white;
    width: 387px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .stores {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .icons {
        display: flex;
        gap: 16px;

        img {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1194px) {
  .talentBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    padding-left: 4%;
    padding-bottom: 4%;

    .leftElement {
      .leftContent {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .bannerImage {
    border-radius: 0px;
  }

  .talentMovileBanner {
    flex-direction: column;
    height: 54rem;
    justify-content: center;

    .image {
      margin-top: 0;
    }
  }
}

@media (max-width: 425px) {
  .talentMovileBanner {
    .description {
      width: 100%;
    }
  }
}

@media (max-width: 620px) {
  .talentBanner {
    gap: 56px;

    .rightElement {
      height: 500px;
    }
  }
}

@media (max-width: 535px) {
  .talentBanner {
    .rightElement {
      height: 400px;
    }
  }
}

@media (max-width: 425px) {
  .talentBanner {
    padding: 32px;

    .leftElement {
      .leftContent {
        .firstElement {
          flex-direction: column;
          align-items: start;
        }
      }
    }
  }
}